import * as React from "react";
import "./index.css";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "FantasqueSansMono",
};
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};
const paragraphStyles = {
  marginBottom: 48,
};

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>404...👽</h1>
      <p style={paragraphStyles}>Nothing sus here...</p>
      <img
        src="https://c.tenor.com/FlpBmxEO-qEAAAAj/the-simpsons-homer-simpson.gif"
        alt="funny animation GIF"
      />
    </main>
  );
};

export default NotFoundPage;
